import React from 'react'
import { Router } from '@reach/router'
import DetallVisita from '../../components/detallVisita'

const Detall = () => {
 
  return ( 
    <Router>
      <DetallVisita path="/veterinaris/detall/:id" id="1" />
    </Router>
  )
}

export default Detall
