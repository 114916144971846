import React, { useState, useEffect } from 'react'
import { getUser} from "../services/auth"
import InformeVisita from "./informeVisita";
import Header from "./header"
import HeaderMenu from "./header-menu"
import Footer from "./footer"


const DetallVisita = ({ id }) => {
 
    const  idVisita  = id;
    const [isLoading, setLoading] = useState(false)
    const [visita,setVisita] = useState({})

    useEffect( () => {
      setLoading(true)
        fetch("/auth/getVisita/"+idVisita ,{ 
          method: 'get', 
          headers: new Headers({
              'Authorization':   getUser().token 
          })
        })
        .then(response=> response.json())        
        .then(_visita => {
          console.log("viista="+JSON.stringify(_visita))
          setVisita(_visita)
          setLoading(false)
        })
      },[])
    return (
      <div className="granjatec-wrapper">
        <Header/>
        <HeaderMenu />
        <div className="main-container">
          <section className="section heading-opt">
            <div className="heading-opt-title">
              <h2 className="h5">Informes</h2>
            </div>
          </section>
          <section className="section form-opt">
            <div className="steps">
              <InformeVisita data={visita} operation={false}/>
              
            </div>
          </section>
          { isLoading && (
            <div className="overlay-loading"></div>
          )}
        </div>
        <Footer/>
      </div>
    )
}
export default DetallVisita;


